
import { computed, defineComponent, reactive, ref } from "vue";
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";
import { useHead } from "@vueuse/head";

import CModal from "@/components/Modal.vue";
import PDataTable from "primevue/datatable";
import PColumn from "primevue/column";
import CDropdown, { DropdownItem } from "@/components/Dropdown.vue";
import CFormCheckbox from "@/components/form/Checkbox.vue";

import { GuideValidation } from "@/store/guide/types";
import { addMoneyMask, formatDateEn } from "@/libs/utils";

const VGuideConfirmValidation = defineComponent({
  name: "VGuideConfirmValidation",
  components: { CModal, PDataTable, PColumn, CDropdown, CFormCheckbox },
  setup() {
    useHead({ title: "Validar itens da guia eletrônica | obmed" });

    const store = useStore();
    const route = useRoute();
    const router = useRouter();

    const guides = computed(() => store.state.guide.validationList);
    const tableItems = computed(() => {
      const items: any[] = [];
      for (const guide of guides.value) {
        for (const guideItem of guide.guiaeletronicaitem_set) {
          items.push({
            ...guideItem,
            nm_paciente: guide.cd_paciente.nm_pessoa_fisica,
            nm_estabelecimento: guide.cd_estabelecimento.nm_fantasia,
          });
        }
      }
      return items;
    });
    const loading = reactive({ guide: false, print: false, printAll: false, submit: false });
    const selectedGuides = ref<{ [key: string]: boolean }>({});

    async function handlePrint(guide: GuideValidation) {
      const _id = String(guide.cd_horario_agendamento);
      const response = await store.dispatch("getPDFGuide", { _id });
      window.open(URL.createObjectURL(new Blob([response?.data], { type: "application/pdf" })));
    }

    async function handlePrintAll() {
      loading.printAll = true;

      const response = await store.dispatch("getPDFGuide", {
        id_guia_eletronica: String(route.query.guide),
      });
      window.open(URL.createObjectURL(new Blob([response?.data], { type: "application/pdf" })));

      loading.printAll = false;
    }

    function getDropdownOptions(guide: GuideValidation) {
      loading.print = true;
      const items: DropdownItem[] = [{ label: "Imprimir", icon: "fas fa-print", command: () => handlePrint(guide) }];
      loading.print = false;

      return items;
    }

    async function getGuides() {
      loading.guide = true;
      const response = await store.dispatch("validateGuide", {
        form: { id: String(route.query.guide), hash: String(route.query.hash) },
      });
      loading.guide = false;

      if (response?.status !== 200) router.back();
    }

    function handleClose() {
      router.back();
    }

    async function onConfirm() {
      const guideIDs = Object.keys(selectedGuides.value).filter((key) => selectedGuides.value[key]);
      if (!guideIDs.length)
        return store.commit("addToast", {
          summary: "Selecione pelo menos uma guia!",
          severity: "warn",
        });

      loading.submit = true;
      const response = await store.dispatch("confirmValidationGuide", {
        form: {
          id: String(route.query.guide),
          hash: String(route.query.hash),
          dt_execucao: formatDateEn(new Date().toDateString()),
          cd_guias: guideIDs,
          cd_estabelecimento: Number(localStorage.getItem("CD_ESTABELECIMENTO")),
        },
      });
      loading.submit = false;

      if (response?.status === 200) {
        router.replace({ name: "guide-list" });
        await store.dispatch("getGuides");
      }
    }

    if (!guides.value.length) getGuides();

    return {
      ...{ guides, loading, selectedGuides, tableItems },
      ...{ handlePrintAll, handleClose, onConfirm, getDropdownOptions, addMoneyMask },
    };
  },
});

export default VGuideConfirmValidation;
